/**
 * Utility for managing the dynamic AZIMUTH acronym meanings
 */

// Define multiple options for each letter of the acronym
const acronymOptions = {
  A: [
    "AUTONOMOUS",
    "ADVANCED",
    "ANALYTICAL",
    "ADAPTIVE",
    "AUGMENTED",
    "ACTIVATED",
    "ACCELERATED",
    "ABSTRACT",
  ],
  Z: [
    "ZENITH",
    "ZERO-POINT",
    "ZONAL",
    "ZETTABYTE",
    "ZERO-LATENCY",
    "ZYGOTIC",
    "ZEITGEIST",
  ],
  I: [
    "INFORMATION",
    "INTELLIGENCE",
    "INTERFACE",
    "INTEGRATION",
    "INFLECTION",
    "ITERATION",
    "INFERENCE",
    "INTROSPECTION",
  ],
  M: [
    "MANAGEMENT",
    "MATRIX",
    "MEMORY",
    "MODULATION",
    "MANIPULATION",
    "METHODOLOGY",
    "MONITORING",
    "MULTI-DIMENSIONAL",
  ],
  U: [
    "UNIVERSAL",
    "UTILITY",
    "UNIFICATION",
    "UNDERSTANDING",
    "UBIQUITOUS",
    "UNLIMITED",
    "ULTRA-SCALE",
  ],
  T: [
    "THINKING",
    "TECHNOLOGY",
    "TEMPORAL",
    "TOPOLOGY",
    "TRANSFORMATION",
    "TRANSCENDENT",
    "THRESHOLD",
    "TELEOLOGICAL",
  ],
  H: [
    "HUB",
    "HEURISTIC",
    "HYPERVISOR",
    "HOLOGRAM",
    "HARMONIZER",
    "HORIZON",
    "HOLONIC",
    "HYPER-SPACE",
  ],
};

/**
 * Generate a random full expansion of the A.Z.I.M.U.T.H acronym
 * @returns Object containing both the full expansion string and individual components
 */
export const generateAcronymMeaning = () => {
  // Select one random option for each letter
  const a =
    acronymOptions.A[Math.floor(Math.random() * acronymOptions.A.length)];
  const z =
    acronymOptions.Z[Math.floor(Math.random() * acronymOptions.Z.length)];
  const i =
    acronymOptions.I[Math.floor(Math.random() * acronymOptions.I.length)];
  const m =
    acronymOptions.M[Math.floor(Math.random() * acronymOptions.M.length)];
  const u =
    acronymOptions.U[Math.floor(Math.random() * acronymOptions.U.length)];
  const t =
    acronymOptions.T[Math.floor(Math.random() * acronymOptions.T.length)];
  const h =
    acronymOptions.H[Math.floor(Math.random() * acronymOptions.H.length)];

  // Combine into a full string
  const fullMeaningUpperCase = `${a} ${z} ${i} ${m} ${u} ${t} ${h}`;

  // Convert to Title Case (first letter of each word capitalized, rest lowercase)
  const fullMeaning = fullMeaningUpperCase
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return {
    full: fullMeaning,
    components: { a, z, i, m, u, t, h },
  };
};

/**
 * Returns the A.Z.I.M.U.T.H acronym with appropriate formatting
 * @param includeFullMeaning Whether to include the full expansion in the title
 * @param acronymPrefixOnly If true, returns only the acronym. If false and includeFullMeaning is true, returns only the full meaning.
 */
export const getFormattedAcronym = (
  includeFullMeaning = false,
  acronymPrefixOnly = true
) => {
  if (includeFullMeaning) {
    const meaning = getCurrentAcronymMeaning();
    if (!acronymPrefixOnly) {
      // Return only the full meaning without the acronym prefix
      return meaning.full;
    }
    return `A.Z.I.M.U.T.H | ${meaning.full}`;
  }
  return "A.Z.I.M.U.T.H";
};

// Cache the acronym meaning for the current session to ensure consistency
let currentAcronymMeaning = generateAcronymMeaning();

/**
 * Get the current acronym meaning (generates a new one first time it's called)
 * @returns The current acronym meaning object
 */
export const getCurrentAcronymMeaning = () => {
  return currentAcronymMeaning;
};

/**
 * Force regeneration of the acronym meaning
 * @param updateTitle Whether to update the document title with the new meaning
 * @returns The newly generated acronym meaning
 */
export const regenerateAcronymMeaning = (updateTitle = true) => {
  currentAcronymMeaning = generateAcronymMeaning();

  // Update the document title if requested
  if (updateTitle && typeof document !== "undefined") {
    document.title = getFormattedAcronym(true, false);
  }

  return currentAcronymMeaning;
};

export default {
  generateAcronymMeaning,
  getCurrentAcronymMeaning,
  regenerateAcronymMeaning,
  getFormattedAcronym,
};
