import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App.tsx";
import { WindowManagerProvider } from "./context/WindowManagerContext";
import { ThemeProvider } from "./context/ThemeContext";
// import { AudioProvider } from './context/AudioContext'
import { LoggingProvider } from "./context/LoggingContext";

// Clear localStorage to reset theme preferences for testing
localStorage.removeItem("aegis-first-run");
localStorage.removeItem("aegis-theme");
console.log("Theme settings reset for testing");

// Initialize rendering directly without additional async initialization
// This will be handled inside App.tsx with useEffect
createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider>
      {/* <AudioProvider> */}
      <LoggingProvider>
        <WindowManagerProvider>
          <App />
        </WindowManagerProvider>
      </LoggingProvider>
      {/* </AudioProvider> */}
    </ThemeProvider>
  </React.StrictMode>
);
