import React, { useCallback, useEffect, useState } from "react";
import { css, Global } from "@emotion/react";
import { keyframes } from "@emotion/react";
import { useTheme } from "./context/ThemeContext";
import { regenerateAcronymMeaning } from "./utils/acronymUtils";
import {
  unlockAudio,
  playSound,
  SOUND_PATHS,
  preloadSounds,
} from "./utils/audioUtils";

// Contexts and Providers
import { WindowManagerProvider } from "./context/WindowManagerContext";
import { AudioProvider } from "./context/AudioContext";

// Components
import BootSequence from "./components/BootSequence";
import Desktop from "./components/Desktop";
import WindowContainer from "./components/WindowContainer";
import ArwesBackground from "./components/ArwesBackground";
// import Taskbar from "./components/Taskbar"; // Commented out due to missing component

/** @jsxImportSource @emotion/react */

// Define animations
const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const pulse = keyframes`
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
`;

const enhancedPulse = keyframes`
  0% { 
    opacity: 0.7; 
    transform: scale(1);
  }
  50% { 
    opacity: 1; 
    transform: scale(1.05);
  }
  100% { 
    opacity: 0.7; 
    transform: scale(1);
  }
`;

// Helper function to dispatch state change events
const dispatchStateChange = (state: string) => {
  const event = new CustomEvent("azimuth:stateChange", {
    detail: { state },
    bubbles: true,
  });
  window.dispatchEvent(event);
};

const ThemedAppWrapper: React.FC = () => {
  const { currentTheme } = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [userInteracted, setUserInteracted] = useState(false);
  const [bootCompleted, setBootCompleted] = useState(false);

  // Update document title when app state changes
  useEffect(() => {
    // Initial temporary title, will be updated by the state change event
    document.title = "AZIMUTH";

    if (isLoading) {
      dispatchStateChange("loading");
    } else if (!userInteracted) {
      dispatchStateChange("intro");
    } else if (!bootCompleted) {
      dispatchStateChange("boot");
    } else {
      dispatchStateChange("desktop");
    }
  }, [isLoading, userInteracted, bootCompleted]);

  // Initialize storage
  const initializeStorage = useCallback(async () => {
    // ... storage initialization logic from App.tsx ...
    // Simplified for this example
    try {
      // Simulate storage initialization
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsLoading(false);
    } catch (error) {
      console.error("Storage initialization error:", error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    initializeStorage();
  }, [initializeStorage]);

  // Enhance the handleInitiateSequence function with the flash animation
  const handleInitiateSequence = async () => {
    // Add the flash animation to the logo when clicked
    const logo = document.querySelector("#aegis-logo");
    if (logo) {
      // Define the flash animation inline
      const flashAnimation = `
        @keyframes flashEffect {
          0% { filter: drop-shadow(0 0 15px ${currentTheme.colorScheme.primary}); }
          50% { filter: drop-shadow(0 0 50px ${currentTheme.colorScheme.accent}); }
          100% { filter: drop-shadow(0 0 15px ${currentTheme.colorScheme.primary}); }
        }
        animation: flashEffect 0.5s ease-in-out;
      `;
      logo.setAttribute("style", flashAnimation);
      setTimeout(() => logo.removeAttribute("style"), 500);
    }

    // Make sure to unlock audio whenever we have user interaction
    try {
      // Always regenerate acronym meaning when initiating the system
      regenerateAcronymMeaning();

      // Try to unlock audio context first - make multiple attempts if needed
      let audioUnlocked = false;
      for (let attempt = 0; attempt < 3; attempt++) {
        audioUnlocked = await unlockAudio();
        if (audioUnlocked) {
          console.log(
            `Audio context successfully unlocked on attempt ${attempt + 1}`
          );
          break;
        }
        // Short wait between attempts
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      // Log the final audio unlock status
      if (!audioUnlocked) {
        console.warn(
          "Failed to unlock audio context after multiple attempts. Some audio features may not work."
        );
      }

      // Play the confirmation sound with a retry
      playSound(SOUND_PATHS.ui.confirm, 0.7).catch(async (err) => {
        console.warn(
          "First sound playback failed, retrying after forced audio unlock:",
          err
        );
        await unlockAudio(); // Try unlocking again
        playSound(SOUND_PATHS.ui.confirm, 0.7).catch((e) =>
          console.error("Sound playback failed even after retry:", e)
        );
      });

      // Update state and proceed with boot sequence
      setUserInteracted(true);

      // Dispatch a custom event to notify other components of state change
      const event = new CustomEvent("azimuth:stateChange", {
        detail: { state: "boot" },
      });
      window.dispatchEvent(event);

      // Preload other sounds in the background for subsequent use
      preloadSounds().catch((err) =>
        console.warn("Error preloading sounds:", err)
      );
    } catch (error) {
      console.error("Error during system initiation:", error);
      // Still proceed even if there are audio issues
      setUserInteracted(true);
    }
  };

  // Add keyboard listener specifically for the Intro sequence
  useEffect(() => {
    // Only add the listener if we are in the intro state
    if (!isLoading && !userInteracted) {
      const handleIntroKeyDown = (event: KeyboardEvent) => {
        // Prevent triggering if modifier keys are pressed
        if (event.ctrlKey || event.altKey || event.metaKey || event.shiftKey) {
          return;
        }
        console.log(
          "[ThemedAppWrapper] Key pressed during intro, initiating sequence."
        );
        handleInitiateSequence();
      };

      document.addEventListener("keydown", handleIntroKeyDown);

      // Cleanup function to remove the listener when the component
      // is no longer in the intro state or unmounts
      return () => {
        document.removeEventListener("keydown", handleIntroKeyDown);
      };
    }
    // Ensure this effect re-runs if the state changes or the handler updates
  }, [isLoading, userInteracted, handleInitiateSequence]);

  const handleBootComplete = useCallback(() => {
    console.log("[App.tsx] handleBootComplete called");
    // Start transition and then show desktop
    setTimeout(() => {
      console.log("[App.tsx] Setting bootCompleted to true");
      setBootCompleted(true);
    }, 1000);
  }, []);

  // Styles for the container of the Initiate Button
  const initiateContainerStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 10, 25, 0.9);
    text-align: center;
    animation: ${fadeIn} 0.8s ease-out;
    position: relative;
    overflow: hidden;

    /* Add subtle grid lines in the background */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(
          to right,
          ${currentTheme.colorScheme.primary}10 1px,
          transparent 1px
        ),
        linear-gradient(
          to bottom,
          ${currentTheme.colorScheme.primary}10 1px,
          transparent 1px
        );
      background-size: 40px 40px;
      opacity: 0.3;
      z-index: -1;
      animation: gridPulse 8s infinite linear;
    }

    @keyframes gridPulse {
      0% {
        opacity: 0.2;
        background-size: 40px 40px;
      }
      50% {
        opacity: 0.4;
        background-size: 42px 42px;
      }
      100% {
        opacity: 0.2;
        background-size: 40px 40px;
      }
    }
  `;

  // Create an animated particles container for the background
  const particlesStyle = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  `;

  // Create a styled logo container
  const logoContainerStyle = css`
    position: relative;
    margin-bottom: 40px;
    animation: float 6s ease-in-out infinite;

    @keyframes float {
      0% {
        transform: translateY(0px);
      }
      50% {
        transform: translateY(-15px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  `;

  // Update the logo style
  const logoStyle = css`
    width: 180px;
    height: auto;
    filter: drop-shadow(0 0 15px ${currentTheme.colorScheme.primary});
    opacity: 0.95;
    transition: all 0.5s ease;
    animation: ${pulse} 3s infinite ease-in-out;

    &:hover {
      filter: drop-shadow(0 0 25px ${currentTheme.colorScheme.primary});
      transform: scale(1.05);
      animation: none;
    }
  `;

  // Update the initiate text style
  const initiateTextStyle = css`
    color: ${currentTheme.colorScheme.text};
    margin: 40px 0;
    font-size: 1rem;
    max-width: 500px;
    line-height: 1.8;
    opacity: 0;
    animation: fadeSlideUp 1s ease-out 0.8s forwards;
    position: relative;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(0, 20, 40, 0.5);
    border: 1px solid ${currentTheme.colorScheme.primary}40;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      border-color: ${currentTheme.colorScheme.primary};
      opacity: 0.7;
    }

    &::before {
      top: -5px;
      left: -5px;
      border-top: 1px solid;
      border-left: 1px solid;
    }

    &::after {
      bottom: -5px;
      right: -5px;
      border-bottom: 1px solid;
      border-right: 1px solid;
    }
  `;

  // Log the state right before rendering
  console.log(
    `[App.tsx] Rendering - bootCompleted: ${bootCompleted}, isLoading: ${isLoading}, userInteracted: ${userInteracted}`
  );

  // Render conditionally based on app state, WRAPPED BY PROVIDERS
  let content;
  if (isLoading) {
    content = (
      <div css={initiateContainerStyle}>
        <img src="/aegis-icon.svg" alt="Azimuth Logo" css={logoStyle} />
        <div css={initiateTextStyle}>SYSTEM STANDBY...</div>
        {/* Spinner */}
        <div
          css={css`
            width: 40px;
            height: 40px;
            border: 4px solid ${currentTheme.colorScheme.secondary || "#555"};
            border-top-color: ${currentTheme.colorScheme.primary};
            border-radius: 50%;
            animation: spin 1s linear infinite;
            @keyframes spin {
              to {
                transform: rotate(360deg);
              }
            }
          `}
        ></div>
      </div>
    );
  } else if (!userInteracted) {
    content = (
      <div css={initiateContainerStyle}>
        {/* Animated background particles */}
        <div css={particlesStyle}>
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              css={css`
                position: absolute;
                width: ${Math.random() * 3 + 1}px;
                height: ${Math.random() * 3 + 1}px;
                background-color: ${currentTheme.colorScheme.primary};
                border-radius: 50%;
                opacity: ${Math.random() * 0.5 + 0.2};
                top: ${Math.random() * 100}%;
                left: ${Math.random() * 100}%;
                animation: particleFloat ${Math.random() * 15 + 10}s linear
                  infinite;

                @keyframes particleFloat {
                  0% {
                    transform: translateY(0) translateX(0);
                  }
                  50% {
                    transform: translateY(-${Math.random() * 100 + 50}px)
                      translateX(${Math.random() * 100 - 50}px);
                  }
                  100% {
                    transform: translateY(0) translateX(0);
                  }
                }
              `}
            />
          ))}
        </div>

        {/* Stylized logo */}
        <div css={logoContainerStyle}>
          <img
            src="/aegis-icon.svg"
            alt="A.Z.I.M.U.T.H"
            css={logoStyle}
            onClick={handleInitiateSequence}
            style={{ cursor: "pointer" }}
            id="aegis-logo"
          />

          {/* User interaction warning notice with pulsing animation */}
          <div
            css={css`
              margin-top: 2rem;
              padding: 1rem;
              border: 2px solid rgba(255, 220, 0, 0.6);
              background-color: rgba(40, 40, 0, 0.3);
              color: rgba(255, 220, 0, 0.9);
              animation: noticePulse 2s infinite;
              font-weight: bold;
              text-shadow: 0 0 5px rgba(255, 220, 0, 0.5);
              max-width: 500px;

              @keyframes noticePulse {
                0% {
                  box-shadow: 0 0 5px rgba(255, 220, 0, 0.5);
                }
                50% {
                  box-shadow: 0 0 15px rgba(255, 220, 0, 0.8);
                }
                100% {
                  box-shadow: 0 0 5px rgba(255, 220, 0, 0.5);
                }
              }
            `}
          >
            <div
              css={css`
                font-size: 1.2rem;
                margin-bottom: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <span
                css={css`
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                  border-radius: 50%;
                  background-color: rgba(255, 220, 0, 0.8);
                  animation: flashWarning 1s infinite;

                  @keyframes flashWarning {
                    0%,
                    100% {
                      opacity: 1;
                    }
                    50% {
                      opacity: 0.5;
                    }
                  }
                `}
              ></span>
              PRESS ANY KEY OR TAP TO START
            </div>
            <div>
              User interaction is required to load audio and core components.
            </div>
            <div
              css={css`
                margin-top: 0.5rem;
                font-size: 0.9rem;
              `}
            >
              Click anywhere or press any key to continue and enable full system
              functionality.
            </div>
          </div>
        </div>

        {/* Engagement text */}
        <div css={initiateTextStyle}>
          <span
            style={{
              color: currentTheme.colorScheme.secondary,
              fontWeight: "bold",
              fontSize: "1.2rem",
              display: "block",
              marginBottom: "15px",
            }}
          >
            USER INTERACTION REQUIRED
          </span>
          This system utilizes modern web browser features that require explicit
          user interaction before activating:
          <ul
            style={{
              textAlign: "left",
              margin: "15px 0",
              paddingLeft: "20px",
              lineHeight: "1.6",
            }}
          >
            <li>Audio processing capabilities</li>
            <li>Core system modules</li>
            <li>Quantum visualization engine</li>
            <li>Advanced UI components</li>
          </ul>
          Browser security protocols prevent automatic audio playback and
          certain system initializations without direct user gesture.
          <br />
          <br />
          <span
            style={{
              color: currentTheme.colorScheme.primary,
              fontWeight: "bold",
              fontSize: "1.1rem",
              display: "block",
              animation: `${enhancedPulse} 2s infinite ease-in-out`,
            }}
          >
            CLICK THE LOGO TO INITIATE SYSTEM
          </span>
          <span
            style={{
              fontSize: "0.8rem",
              opacity: 0.8,
              display: "block",
              marginTop: "5px",
            }}
          >
            (or press any key)
          </span>
        </div>
      </div>
    );
  } else if (!bootCompleted) {
    // BootSequence no longer needs its own provider
    content = <BootSequence onComplete={handleBootComplete} />;
  } else {
    // Desktop environment no longer needs its own provider
    content = (
      <Desktop>
        <WindowContainer />
      </Desktop>
    );
  }

  // Apply global styles and wrap content with providers ONCE
  // Debug background information
  console.log("Rendering ArwesBackground with theme:", {
    backgroundType: currentTheme.background.type,
    backgroundId: currentTheme.background.id,
  });

  return (
    <WindowManagerProvider>
      <AudioProvider>
        <Global styles={globalStyles()} />
        <ArwesBackground />
        <div css={appContainerStyle}>{content}</div>
      </AudioProvider>
    </WindowManagerProvider>
  );
};

export default ThemedAppWrapper;

// Define styles used above (loadingContainerStyle, etc.) ...
const appContainerStyle = css`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const globalStyles = () => css`
  @import url("https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap");

  :root {
    /* Set default font directly */
    --font-family-main: "Share Tech Mono", monospace;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family-main);
    overflow: hidden; /* Prevent scrolling */
    /* Background/color set by theme provider or specific components */
  }

  /* Basic Scrollbar Styling - Assuming Arwes handles this now */

  * {
    box-sizing: border-box;
  }
`;

// ... (styles for loading, initiate button, etc.) ...
