import React, { useEffect } from "react";
import ArwesThemeProvider from "./components/ArwesThemeProvider";
import { ThemeProvider } from "./context/ThemeContext";
import ThemedAppWrapper from "./ThemedAppWrapper";

// Define the custom event type for state changes
interface AzimuthStateChangeEvent extends CustomEvent {
  detail: { state: string };
}

// Main App component now just sets up providers and manages the document title
const App: React.FC = () => {
  // Dynamic document title based on application state
  useEffect(() => {
    // Define a function to update the document title based on app state
    const updateDocumentTitle = (state?: string) => {
      const baseTitle = "A.Z.I.M.U.T.H";

      // Create contextual title based on state
      switch (state) {
        case "loading":
          document.title = `${baseTitle} | Initializing`;
          break;
        case "intro":
          document.title = `${baseTitle} | System Standby`;
          break;
        case "boot":
          document.title = `${baseTitle} | System Boot`;
          break;
        case "desktop":
          document.title = "Interface Active";
          break;
        default:
          document.title = baseTitle;
      }
    };

    // Initial title
    updateDocumentTitle();

    // Create a custom event listener for changing the title
    const handleTitleChange = (event: AzimuthStateChangeEvent) => {
      updateDocumentTitle(event.detail.state);
    };

    // Add event listener for title changes
    window.addEventListener(
      "azimuth:stateChange",
      handleTitleChange as EventListener
    );

    return () => {
      // Remove the event listener when component unmounts
      window.removeEventListener(
        "azimuth:stateChange",
        handleTitleChange as EventListener
      );
    };
  }, []);

  return (
    <ArwesThemeProvider>
      <ThemeProvider>
        <ThemedAppWrapper />
      </ThemeProvider>
    </ArwesThemeProvider>
  );
};

export default App;
